import CreatorAdd from "../components/creatorAdd.component.js";

const CreatorAddPage = () => {

    return (
    <div>
        <CreatorAdd />
    </div>
    )}
    
    export default CreatorAddPage
import PairingAdd from "../components/pairingAdd.component.js";

const PairingAddPage = () => {

    return (
    <div>
        <PairingAdd />
    </div>
    )}
    
    export default PairingAddPage
import CreatorEdit from "../components/creatorEdit.component.js";

const CreatorEditPage = () => {

    return (
    <div>
        <CreatorEdit />
    </div>
    )}
    
    export default CreatorEditPage
import RecipeEdit from "../components/recipeEdit.component.js";

const RecipeEditPage = () => {

    return (
    <div>
        <RecipeEdit />
    </div>
    )}
    
    export default RecipeEditPage
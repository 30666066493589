import Register from "../components/register.component.js";

const RegisterPage = () => {

return (
<div>
    <Register />
</div>
)}

export default RegisterPage
import RecipeAdd from "../components/recipeAdd.component.js";

const RecipeAddPage = () => {

    return (
    <div>
        <RecipeAdd />
    </div>
    )}
    
    export default RecipeAddPage
import PairingEdit from "../components/pairingEdit.component.js";

const PairingEditPage = () => {

    return (
    <div>
        <PairingEdit />
    </div>
    )}
    
    export default PairingEditPage
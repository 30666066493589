import Login from "../components/login.component.js";

const LoginPage = () => {

return (
<div>
    <Login />
</div>
)}

export default LoginPage
import Home from "../components/home.component.js";

const HomePage = () => {

return (
<div>
    <Home />
</div>
)}

export default HomePage
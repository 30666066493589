import RegionAdd from "../components/regionAdd.component.js";

const RegionAddPage = () => {

    return (
    <div>
        <RegionAdd />
    </div>
    )}
    
    export default RegionAddPage
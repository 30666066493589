import Profile from "../components/profile.component.js";

const ProfilePage = () => {

    return (
    <div>
        <Profile />
    </div>
    )}
    
    export default ProfilePage